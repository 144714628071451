/* global $ */

import { openOverlay, closeOverlay } from './utils';

// Cart behaviour
const shoppingBar = $('.shopping-bar');
const cartToggle = $('.sidebar-cart-toggle');
const cart = $('.sidebar-cart');
const cartClose = $('.sidebar-cart-close');

cartToggle.click((evt) => {
  evt.preventDefault();
  cart.addClass('active');
  openOverlay();
  shoppingBar.removeClass('active');
});

cartClose.click((evt) => {
  evt.preventDefault();
  cart.removeClass('active');
  closeOverlay();
});
