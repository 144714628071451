/* eslint-disable new-cap, no-new */
/* global $, autoComplete */

import { isMobile } from './utils';

require('babel-polyfill');

new autoComplete({
  selector: (isMobile) ? '#seeker-mobile' : '#seeker',
  data: {
    src: async () => {
      // Fetch Data from external Source
      const source = await fetch('/js/data.json');
      const data = await source.json();

      // Returns Fetched data
      return data;
    },
    key: ['name'],
    cache: true,
  },
  // data: {
  //   src: [
  //     {
  //       name: 'Perfume 1',
  //       link: '/producto_detalle.html',
  //       price: '800',
  //       image: 'gfx/png/frasco_pequeno_xs_1.png',
  //     },
  //     {
  //       name: 'Perfume 2',
  //       link: '/producto_detalle.html',
  //       price: '800',
  //       image: 'gfx/png/frasco_pequeno_xs_1.png',
  //     },
  //     {
  //       name: 'Perfume 3',
  //       link: '/producto_detalle.html',
  //       price: '800',
  //       image: 'gfx/png/frasco_pequeno_xs_1.png',
  //     },
  //   ],
  //   key: ['name'],
  // },
  resultsList: {
    destination: (isMobile) ? '#results-mobile' : '#seeker',
    noResults: (list, query) => {
      // Create "No Results" message list element
      const message = document.createElement('li');
      message.setAttribute('class', 'autoComplete_result');
      // Add message text content
      message.innerHTML = `<span>No se encontraron resultados para "${query}"</span>`;
      // Add message list element to the list
      list.appendChild(message);
    },
  },
  resultItem: {
    content: (item, element) => {
      $(element).html(`
        <a href="${item.value.link}">
          <div class="row align-items-center justify-content-between">
            <div class="col-2 pe-4">
              <img class="img-fluid" src="${item.value.image}">
            </div>
            <div class="col-7">
              ${item.match}
            </div>
            <div class="col-3 text-end">
              <span class="highlight-text">S/ ${item.value.price}</span>
            </div>
          </div>
        </>
      `);
    },
    highlight: {
      render: true,
    },
  },
});
