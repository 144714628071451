// Owl Carousel behaviour
/* global $ */

$(() => {
  $('.carousel-big-banners').owlCarousel({
    smartSpeed: 1000,
    loop: true,
    nav: false,
    items: 1,
    dots: true,
    center: true,
    autoplay: true,
    margin: 10,
  });

  $('.carousel-banners').owlCarousel({
    smartSpeed: 1000,
    nav: true,
    navText: [
      '<span class="icon-chevron-left" aria-hidden="true"></span>',
      '<span class="icon-chevron-right" aria-hidden="true"></span>',
    ],
    items: 1,
    dots: false,
    center: true,
    responsive: {
      0: {
        dots: true,
        nav: false,
      },
      991: {
        dots: false,
        nav: true,
      },
    },
  });

  $('.carousel-products').owlCarousel({
    smartSpeed: 1000,
    navText: [
      '<span class="icon-chevron-left" aria-hidden="true"></span>',
      '<span class="icon-chevron-right" aria-hidden="true"></span>',
    ],
    margin: 15,
    responsive: {
      0: {
        items: 1,
        dots: true,
        nav: false,
        stagePadding: 50,
        autoWidth: true,
        center: true,
      },
      991: {
        items: 4,
        dots: false,
        nav: true,
      },
    },
  });

  $('.carousel-brands').owlCarousel({
    smartSpeed: 1000,
    nav: false,
    dots: false,
    margin: 20,
    responsive: {
      0: {
        items: 1,
        dots: true,
        stagePadding: 50,
        autoWidth: true,
        center: true,
        margin: 10,
      },
      991: {
        items: 3,
        mouseDrag: false,
        touchDrag: false,
        pullDrag: false,
      },
    },
  });

  $('.carousel-articles').owlCarousel({
    smartSpeed: 1000,
    nav: true,
    navText: [
      '<span class="icon-chevron-left" aria-hidden="true"></span>',
      '<span class="icon-chevron-right" aria-hidden="true"></span>',
    ],
    items: 1,
    dots: true,
  });

  $('.carousel-articles-2').owlCarousel({
    smartSpeed: 1000,
    navText: [
      '<span class="icon-chevron-left" aria-hidden="true"></span>',
      '<span class="icon-chevron-right" aria-hidden="true"></span>',
    ],
    margin: 10,
    responsive: {
      0: {
        items: 1,
        dots: true,
        nav: false,
        stagePadding: 50,
        autoWidth: true,
        margin: 10,
        center: true,
      },
      991: {
        items: 2,
        dots: false,
        nav: true,
      },
    },
  });

  $('.carousel-blog-banners').owlCarousel({
    smartSpeed: 1000,
    navText: [
      '<span class="icon-chevron-left" aria-hidden="true"></span>',
      '<span class="icon-chevron-right" aria-hidden="true"></span>',
    ],
    items: 1,
    responsive: {
      0: {
        dots: true,
        nav: false,
      },
      991: {
        dots: true,
        nav: true,
      },
    },
  });

  $('.carousel-comments').owlCarousel({
    smartSpeed: 1000,
    nav: false,
    margin: 20,
    responsive: {
      0: {
        items: 1,
        stagePadding: 50,
        autoWidth: true,
        center: true,
        dots: true,
        margin: 10,
      },
      991: {
        items: 3,
        mouseDrag: false,
        touchDrag: false,
        dots: false,
        pullDrag: false,
      },
    },
  });
});
