/* eslint-disable new-cap, no-new */
/* global $, bootstrap */
  import {
    isMobile, openToast, openOverlay, closeOverlay,
  } from './utils';

  import './menu_sidebar';
  import './car_sidebar';
  import './menu_profile';
  import './menu_bar';
  import './range_slider';
  import './search';
  import './carousels';
  import './validate';

  // Suscripcion

  const sendSubscription = $('.send-subscription');
  const formSubscription = $('.form-subscription');
  const thanksSubscription = $('.thanks-subscription');

  if (sendSubscription.length) {
    sendSubscription.click((evt) => {
      evt.preventDefault();
      formSubscription.addClass('visually-hidden');
      thanksSubscription.removeClass('visually-hidden');
    });
  }

  // Shopping bar

  const shoppingBar = $('.shopping-bar');
  const productDetail = $('.product-detail');

  if (shoppingBar.length) {
    window.addEventListener('scroll', () => {
      const currentScroll = window.pageYOffset;
      if (currentScroll <= 0) {
        shoppingBar.removeClass('active');
        return;
      }

      if (currentScroll > productDetail.offset().top + productDetail.height()) {
        if (!$(document.body).hasClass('overlay-open')) {
          shoppingBar.addClass('active');
        }
      } else {
        shoppingBar.removeClass('active');
      }
    });
  }

  // Product filters behaviour
  const filtersToggle = $('.product-filters-toggle');
  const filters = $('.product-filters');
  const filtersClose = $('.product-filters-close');

  filtersToggle.click((evt) => {
    evt.preventDefault();
    filters.addClass('active');
    openOverlay();
  });

  filtersClose.click((evt) => {
    evt.preventDefault();
    filters.removeClass('active');
    closeOverlay();
  });

  // Carousel products: Add to cart and add to fav

  const addToCart = $('.add-to-cart');
  addToCart.click((evt) => {
    evt.preventDefault();
    const self = $(evt.currentTarget);
    const info = self.closest('.product-item-head').find('.product-item-info');
    info.hide();

    if (self.hasClass('checked')) {
      info.text('Haz quitado de la bolsa');
      self.toggleClass('checked');
      self.find('span').toggleClass('icon-cart').toggleClass('icon-cart-inner');
    } else {
      info.text('Haz agregado a la bolsa');
      self.toggleClass('checked');
      self.find('span').toggleClass('icon-cart').toggleClass('icon-cart-inner');
    }

    info.fadeIn().delay(1000).fadeOut();
  });

  const addToFav = $('.add-to-fav');
  addToFav.click((evt) => {
    evt.preventDefault();
    const self = $(evt.currentTarget);
    const info = self.closest('.product-item-head').find('.product-item-info');
    info.hide();

    if (self.hasClass('checked')) {
      info.text('Haz quitado de favoritos');
      self.toggleClass('checked');
      self.find('span').toggleClass('icon-heart').toggleClass('icon-heart-inner');
    } else {
      info.text('Haz agregado a favoritos');
      self.toggleClass('checked');
      self.find('span').toggleClass('icon-heart').toggleClass('icon-heart-inner');
    }

    info.fadeIn().delay(1000).fadeOut();
  });

  // Add favourites to cart

  const addFavToCart = $('.add-fav-to-cart');

  addFavToCart.click((evt) => {
    evt.preventDefault();
    openToast('Haz agregado a la bolsa');
  });
  // Tabs behaviour

  const triggerTabList = document.querySelectorAll('.nav-tabs .nav-link');
  triggerTabList.forEach((triggerEl) => {
    const tabTrigger = $(triggerEl).data('bsTarget');
    const tabPane = $(triggerEl).parent().next().find('.tab-pane');
    const navLink = $(triggerEl).parent().find('.nav-link');

    triggerEl.addEventListener('click', (event) => {
      event.preventDefault();
      navLink.removeClass('active');
      $(triggerEl).addClass('active');
      tabPane.removeClass('show').removeClass('active');
      $(tabTrigger).addClass('show').addClass('active');
    });
  });

  // Image zoom

  const configZoom = {
    zoomWindowWidth: 400,
    zoomWindowHeight: 300,
    // zoomWindowOffsetX: 50,
    // zoomWindowOffsetY: 50,
    lensFadeIn: true,
    lensFadeOut: true,
    zoomWindowFadeIn: true,
    zoomWindowFadeOut: true,
    borderSize: 1,
    borderColour: '#E5E5E5',
    lensBorder: '0',
    lensColour: 'rgba(166, 212, 255, 0.5)',
    lensOpacity: 1,
    zoomWindowPosition: '.product-detail-info',
  };

  if (!isMobile) {
    $('.product-detail-picture img').ezPlus(configZoom);
  }

  const thumbnails = $('.product-detail-thumbnail');

  thumbnails.click((evt) => {
    evt.preventDefault();
    const target = evt.currentTarget;
    const picture = $('.product-detail-picture img');

    picture.attr('src', $(target).data('img'));
    picture.attr('data-zoom-image', $(target).data('zoomImage'));

    if (!isMobile) {
      const ez = picture.data('ezPlus');
      ez.closeAll();
      picture.ezPlus(configZoom);
    }
  });

  // Cart

  const personalization = $('input[name="personalization"]');

  personalization.change((evt) => {
    const target = evt.currentTarget;
    if (target.id === 'personalization2') {
      $('#personalization-message').show().prop( 'required', true );
    } else {
      $('#personalization-message').hide().prop( 'required', false );
    }
  });

  const sample = $('input[name="sample"]');

  sample.change((evt) => {
    const target = evt.currentTarget;
    if (target.id === 'sample2') {
      $('#samples').show();
      $('.samples-item input').prop( 'required', true );
    } else {
      $('#samples').hide();
      $('.samples-item input').prop( 'required', false );
    }
  });

  const samplesItem = $('.samples-item input');
  const samplesLimit = 2;

  samplesItem.change((evt) => {
    const target = evt.currentTarget;
    if (samplesItem.filter(':checked').length > samplesLimit) {
      target.checked = false;
    }

    if (samplesItem.filter(':checked').length >= samplesLimit) {
      $('.samples-item input:not(:checked)').addClass('disabled').prop( 'required', false );
    }else{
      $('.samples-item input:not(:checked)').removeClass('disabled').prop( 'required', true );
    }
  });

  // Pago

  const register = $('.register-user');
  const continuAsGuest = $('.continue-as-guest');
  const backSession = $('.back-session');
  let formLogin = $('.user-session-login');
  let formCreate = $('.user-session-create');
  let formGuest = $('.user-session-guest');
  let formActive = $('.user-session-active');

  register.click((evt) => {
    evt.preventDefault();
    const target = evt.currentTarget;
    const parent = $(target).closest('.user-session');
    formLogin = parent.find('.user-session-login');
    formCreate = parent.find('.user-session-create');
    formLogin.hide();
    formCreate.fadeIn();
  });

  continuAsGuest.click((evt) => {
    evt.preventDefault();
    const target = evt.currentTarget;
    const parent = $(target).closest('.user-session');
    formLogin = parent.find('.user-session-login');
    formGuest = parent.find('.user-session-guest');
    formLogin.hide();
    formGuest.fadeIn();
  });

  backSession.click((evt) => {
    evt.preventDefault();
    const target = evt.currentTarget;
    const parent = $(target).closest('.user-session');
    formLogin = parent.find('.user-session-login');
    formCreate = parent.find('.user-session-create');
    formGuest = parent.find('.user-session-guest');
    formLogin.fadeIn();
    formCreate.hide();
    formGuest.hide();
    formActive.hide();
  });

  const editInfo = $('.user-edit-info');

  editInfo.click((evt) => {
    evt.preventDefault();
    const target = evt.currentTarget;
    //$('.user-billing, .user-shipping, .user-payment').removeClass('active');
    $('.user-billing, .user-shipping').removeClass('active');
    $(target)
      //.closest('.user-billing, .user-shipping, .user-payment')
      .closest('.user-billing, .user-shipping')
      .addClass('active');
  });

/*   const seguirSinFactura = $('#seguir-sin-factura');

  seguirSinFactura.click((evt) => {
    evt.preventDefault();
    $('.user-billing').removeClass('active');
    $('#sin-facturacion').show();
    $('#con-facturacion').hide();
    $('.user-shipping').removeClass('disabled').addClass('active');
  });

  const continuarFacturacion = $('#continuar-facturacion');

  continuarFacturacion.click((evt) => {
    evt.preventDefault();
    $('.user-billing').removeClass('active');
    $('#sin-facturacion').hide();
    $('#con-facturacion').show();
    $('.user-shipping').removeClass('disabled').addClass('active');
  });

  const aceptarDirFact = $('#aceptar-dir-fact');

  aceptarDirFact.click((evt) => {
    evt.preventDefault();
    $('.user-billing').removeClass('active');
    $('#sin-facturacion').hide();
    $('#con-facturacion').show();
    $('.user-shipping').removeClass('disabled').addClass('active');
  });

  const continuarEntrega = $('#continuar-entrega');

  continuarEntrega.click((evt) => {
    evt.preventDefault();
    $('.user-shipping').removeClass('active');
    $('.user-payment').removeClass('disabled').addClass('active');
  });

  const aceptarDirEnt = $('#aceptar-dir-ent');

  aceptarDirEnt.click((evt) => {
    evt.preventDefault();
    $('.user-shipping').removeClass('active');
    $('.user-payment').removeClass('disabled').addClass('active');
  }); */

  // Activate popovers

  const popoverTriggerList = [].slice.call(
    document.querySelectorAll('[data-bs-toggle="popover"]'),
  );
  popoverTriggerList.map(
    (popoverTriggerEl) => new bootstrap.Popover(popoverTriggerEl),
  );

  // eslint-disable-next-line no-unused-vars
  // const popover = new bootstrap.Popover(document.querySelector('.popover-dismiss'), {
  //   trigger: 'focus',
  // });

  // Mis datos personales

  const saveChanges = $('.save-changes');

  saveChanges.click(() => {
    openToast('Los datos han sido guardados');
  });

  const changePassword = $('.change-password');

  changePassword.click((evt) => {
    evt.preventDefault();
    const target = evt.currentTarget;
    const parent = $(target).closest('.col-lg-6');
    const next1 = parent.next();
    const next2 = parent.next().next();
    parent.addClass('visually-hidden');
    next1.removeClass('visually-hidden');
    next2.removeClass('visually-hidden');
  });

  const savePassword = $('.save-password');

  savePassword.click((evt) => {
    evt.preventDefault();
    const target = evt.currentTarget;
    const parent = $(target).closest('.col-12');
    const previous1 = parent.prev();
    const previous2 = parent.prev().prev();
    parent.addClass('visually-hidden');
    previous1.addClass('visually-hidden');
    previous2.removeClass('visually-hidden');
  });

  // Mis direcciones

  const listAddresses = $('.list-addresses');
  const listAddressesAdd = $('.list-addresses-add');

  listAddresses.on('click', '.address-item-action-edit', (evt) => {
    evt.preventDefault();
    const target = evt.currentTarget;
    const parentAddress = $(target).closest('.address-item');
    parentAddress.addClass('editting');
    parentAddress.find('input, select').attr('disabled', false);
  });

  listAddresses.on('click', '.address-item-action-select', (evt) => {
    evt.preventDefault();
    const target = evt.currentTarget;
    const parentAddress = $(target).closest('.address-item');
    $('.address-item').removeClass('default');
    parentAddress.addClass('default');
  });

  listAddresses.on('click', '.address-item-action-delete', (evt) => {
    evt.preventDefault();
    const target = evt.currentTarget;
    const parentAddress = $(target).closest('.address-item');
    $(parentAddress).remove();
    if (listAddresses.children().length === 0) {
      listAddresses.empty();
    }
  });

  listAddresses.on('click', '.address-item-edit-save', (evt) => {
    evt.preventDefault();
    const target = evt.currentTarget;
    const parentAddress = $(target).closest('.address-item');
    parentAddress.removeClass('editting');
    parentAddress.find('input, select').attr('disabled', true);
  });

  listAddresses.on('click', '.address-item-edit-discard', (evt) => {
    evt.preventDefault();
    const target = evt.currentTarget;
    const parentAddress = $(target).closest('.address-item');
    parentAddress.removeClass('editting');
    parentAddress.find('input, select').attr('disabled', true);
  });

  listAddresses.on('click', '.address-item-add-save', (evt) => {
    evt.preventDefault();
    const target = evt.currentTarget;
    const parentAddress = $(target).closest('.address-item');
    parentAddress.removeClass('adding');
    parentAddress.find('input, select').attr('disabled', true);
    listAddressesAdd.removeClass('disabled');
  });

  listAddresses.on('click', '.address-item-add-discard', (evt) => {
    evt.preventDefault();
    const target = evt.currentTarget;
    const parentAddress = $(target).closest('.address-item');
    $(parentAddress).remove();
    if (listAddresses.children().length === 0) {
      listAddresses.empty();
    }
    listAddressesAdd.removeClass('disabled');
  });

  const currentPage = $('#current-page');
  let addressItem = '';

  if (currentPage.val() === 'direcciones-facturacion') {
    addressItem = `<div class="address-item adding">
                      <div class="border p-4">
                        <form class="row g-3 needs-validation" novalidate="">
                          <div class="col-lg-8">
                            <div class="row g-3">
                              <div class="col-12">
                                <input class="form-control" type="text" placeholder="Razón social" required="">
                              </div>
                              <div class="col-6">
                                <select class="form-select" required="">
                                  <option value="" selected="" disabled="">Departamento</option>
                                </select>
                              </div>
                              <div class="col-6">
                                <select class="form-select" required="">
                                  <option value="" selected="" disabled="">Provincia</option>
                                </select>
                              </div>
                              <div class="col-6">
                                <select class="form-select" required="">
                                  <option value="" selected="" disabled="">Distrito</option>
                                </select>
                              </div>
                              <div class="col-6">
                                <input class="form-control" type="text" placeholder="Teléfono" required="">
                              </div>
                              <div class="col-12">
                                <input class="form-control" type="text" placeholder="Dirección" required="">
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-4 address-item-actions">
                            <div class="row g-3 text-lg-end">
                              <div class="col col-lg-12"><a class="btn btn-linked address-item-action-edit" href="#"><span class="icon-arrow-left pe-2"></span>Editar</a></div>
                              <div class="col col-lg-12"><a class="btn btn-linked address-item-action-select" href="#">Principal</a></div>
                              <div class="col col-lg-12"><a class="btn btn-linked address-item-action-delete" href="#">Eliminar</a></div>
                            </div>
                          </div>
                          <div class="col-lg-4 address-item-edit">
                            <div class="d-lg-grid gap-3 d-flex justify-content-around">
                              <button class="btn btn-black address-item-edit-save" type="submit">Guardar</button><a class="btn btn-white address-item-edit-discard" href="#">Descartar</a>
                            </div>
                          </div>
                          <div class="col-lg-4 address-item-add">
                            <div class="d-lg-grid gap-3 d-flex justify-content-around">
                              <button class="btn btn-black address-item-add-save" type="submit">Agregar</button><a class="btn btn-white address-item-add-discard" href="#">Descartar</a>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>`;
  } else if (currentPage.val() === 'direcciones-entrega') {
    addressItem = `<div class="address-item adding">
                      <div class="border p-4">
                        <form class="row g-3 needs-validation" novalidate="">
                          <div class="col-lg-8">
                            <div class="row g-3">
                              <div class="col-12">
                                <input class="form-control" type="text" placeholder="Razón social" required="">
                              </div>
                              <div class="col-6">
                                <select class="form-select" required="">
                                  <option value="" selected="" disabled="">Departamento</option>
                                </select>
                              </div>
                              <div class="col-6">
                                <select class="form-select" required="">
                                  <option value="" selected="" disabled="">Provincia</option>
                                </select>
                              </div>
                              <div class="col-6">
                                <select class="form-select" required="">
                                  <option value="" selected="" disabled="">Distrito</option>
                                </select>
                              </div>
                              <div class="col-6">
                                <input class="form-control" type="text" placeholder="Teléfono" required="">
                              </div>
                              <div class="col-12">
                                <input class="form-control" type="text" placeholder="Dirección" required="">
                              </div>
                              <div class="col-6">
                                <input class="form-control" type="text" placeholder="Número" required="">
                              </div>
                              <div class="col-6">
                                <input class="form-control" type="text" placeholder="Piso" required="">
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-4 address-item-actions">
                            <div class="row g-3 text-lg-end">
                              <div class="col col-lg-12"><a class="btn btn-linked address-item-action-edit" href="#"><span class="icon-arrow-left pe-2"></span>Editar</a></div>
                              <div class="col col-lg-12"><a class="btn btn-linked address-item-action-select" href="#">Principal</a></div>
                              <div class="col col-lg-12"><a class="btn btn-linked address-item-action-delete" href="#">Eliminar</a></div>
                            </div>
                          </div>
                          <div class="col-lg-4 address-item-edit">
                            <div class="d-lg-grid gap-3 d-flex justify-content-around">
                              <button class="btn btn-black address-item-edit-save" type="submit">Guardar</button><a class="btn btn-white address-item-edit-discard" href="#">Descartar</a>
                            </div>
                          </div>
                          <div class="col-lg-4 address-item-add">
                            <div class="d-lg-grid gap-3 d-flex justify-content-around">
                              <button class="btn btn-black address-item-add-save" type="submit">Agregar</button><a class="btn btn-white address-item-add-discard" href="#">Descartar</a>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>`;
  }

  listAddressesAdd.click((evt) => {
    evt.preventDefault();
    listAddresses.append(addressItem);
    listAddressesAdd.addClass('disabled');
  });
