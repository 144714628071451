/* global $ */

import { isMobile, openOverlay, closeOverlay } from './utils';

// Menu profile behaviour
const menuProfileToggle = $('.menu-profile-toggle');
const menuProfile = $('.menu-profile');
const menuProfileClose = $('.menu-profile-close');

if (isMobile) {
  menuProfileToggle.click((evt) => {
    evt.preventDefault();
    menuProfile.addClass('active');
    openOverlay();
  });

  menuProfileClose.click((evt) => {
    evt.preventDefault();
    menuProfile.removeClass('active');
    closeOverlay();
  });
}
