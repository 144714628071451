/* global $ */

import { openOverlay, closeOverlay } from './utils';

// Menu bar mobile behaviour
const seekerBarToggle = $('#seeker-bar-toggle');
const seekerBar = $('.seeker-bar');
const seekerBarClose = $('.seeker-bar-close');

seekerBarToggle.click((evt) => {
  evt.preventDefault();
  seekerBar.addClass('active');
  openOverlay();
});

seekerBarClose.click((evt) => {
  evt.preventDefault();
  seekerBar.removeClass('active');
  closeOverlay();
});
