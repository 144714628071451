/* eslint-disable radix */
/* global $ */

$(document).ready(() => {
  $('#price-range-submit').hide();

  $('#min_price,#max_price').on('change', () => {
    $('#price-range-submit').show();

    const minPriceRange = parseInt($('#min_price').val());

    const maxPriceRange = parseInt($('#max_price').val());

    if (minPriceRange > maxPriceRange) {
      $('#max_price').val(minPriceRange);
    }

    $('#slider-range').slider({
      values: [minPriceRange, maxPriceRange],
    });
  });

  $('#min_price,#max_price').on('paste keyup', () => {
    $('#price-range-submit').show();

    const minPriceRange = parseInt($('#min_price').val());

    let maxPriceRange = parseInt($('#max_price').val());

    if (minPriceRange === maxPriceRange) {
      maxPriceRange = minPriceRange + 100;

      $('#min_price').val(minPriceRange);
      $('#max_price').val(maxPriceRange);
    }

    $('#slider-range').slider({
      values: [minPriceRange, maxPriceRange],
    });
  });

  $(() => {

    $('#slider-range').slider({
      range: true,
      orientation: 'horizontal',
      min: 1,
      max: $('#max_price').attr('data-max'),
      values: [$('#min_price').val(), $('#max_price').val()],
      step: 1,

      slide: (event, ui) => {
        if (ui.values[0] === ui.values[1]) {
          return false;
        }

        $('#min_price').val(ui.values[0]);
        $('#max_price').val(ui.values[1]);
        return true;
      },
    });

    $('#min_price').val($('#slider-range').slider('values', 0));
    $('#max_price').val($('#slider-range').slider('values', 1));
  });
});
