/* global $ */

const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

const openToast = (message) => {
  const messageToast = $('.message-toast');
  const toast = $('.toast');
  messageToast.text(message);
  toast.toast('show');
};

const openOverlay = () => {
  $(document.body).append('<div class="overlay"></div>').addClass('overlay-open');
  setTimeout(() => {
    $('.overlay').addClass('show');
  }, 100);
};

const closeOverlay = () => {
  $(document.body).removeClass('overlay-open');
  $('.overlay').removeClass('show');
  setTimeout(() => {
    $('.overlay').remove();
  }, 400);
};

export {
  isMobile,
  openToast,
  openOverlay,
  closeOverlay,
};
